exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-projects-atlas-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=C:/Users/atlas/Documents/Development/werebear-woodworks/projects/atlas/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-projects-atlas-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-projects-pat-desk-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=C:/Users/atlas/Documents/Development/werebear-woodworks/projects/pat-desk/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-projects-pat-desk-index-mdx" */),
  "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-projects-wedding-arch-index-mdx": () => import("./../../../src/pages/projects/{mdx.frontmatter__slug}.jsx?__contentFilePath=C:/Users/atlas/Documents/Development/werebear-woodworks/projects/wedding-arch/index.mdx" /* webpackChunkName: "component---src-pages-projects-mdx-frontmatter-slug-jsx-content-file-path-projects-wedding-arch-index-mdx" */)
}

